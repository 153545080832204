export const environment = {
  name: 'Dev',
  production: false,
  apiBaseUrl: 'https://dev.api.crowdrock.app/admin',
  brand: {
    name: 'Crowdrock',
  },
  sentryDSN: '',
  passwordDialogPassword: '',
  hideWalletRelatedFeatures: false,
  roundDashboardValues: true,
};
